import React from "react";
import { Link } from "react-router-dom";

function closeMenu() {
  document.querySelector("#menu-btn").click();
}

const Header = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light">
        <button
          id="menu-btn"
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars"></i>
        </button>
        <div className="navbar-brand col">
          <h1 className="brand">
            <a href="/">
              <img
                className="brand-logo"
                src={require("../utils/img/logo/logo-icon-blue.jpg")}
              />
            </a>
            <a href="/">
              <img
                className="brand-logo"
                src={require("../utils/img/logo/logo-text-blue.jpg")}
              />
            </a>
          </h1>
          {/* <small>Healthcare Services</small> */}
        </div>
        <div className="main-menu collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav ml-auto" id="navigation">
            <li>
              <Link onClick={closeMenu} to="/">
                Home
              </Link>
            </li>
            <li>
              <Link onClick={closeMenu} to="/about">
                About Us
              </Link>
            </li>
            <li>
              <Link onClick={closeMenu} to="/careers">
                Careers
              </Link>
            </li>
            <li>
              <Link onClick={closeMenu} to="/referral">
                Refer Us
              </Link>
            </li>
            <li>
              <Link onClick={closeMenu} to="/services">
                Services
              </Link>
              <ul className="submenu d-none d-lg-block">
                {/* <li className="">
                  <Link to="/services">· Services Overview</Link>
                </li> */}
                <li className="">
                  <Link to="/services/pca-service">
                    PCA Service/Community First Services and Supports (CFSS)
                  </Link>
                </li>
                <li className="">
                  <Link to="/services/245D-waivered-services">
                    245D Waivered Services
                  </Link>
                </li>
                <li className="">
                  <a className="submenu-title" disabled>
                    Basic Support Services
                  </a>
                </li>
                <li className="">
                  <Link to="/services/24-hours-emergency-assistance">
                    · 24 hours emergency assistance
                  </Link>
                </li>
                <li className="">
                  <Link to="/services/overnight-services">
                    · Overnight Services
                  </Link>
                </li>
                <li className="">
                  <Link to="/services/companionship">· Companionship</Link>
                </li>
                <li className="">
                  <Link to="/services/live-in-caregiver-services">
                    · Live-In Caregiver Services
                  </Link>
                </li>
                <li className="">
                  <a className="submenu-title" disabled>
                    Personal Support
                  </a>
                </li>
                <li className="">
                  <Link to="/services/assistance-with-bathing-dressing">
                    · Assistance with Bathing & Dressing
                  </Link>
                </li>
                <li className="">
                  <Link to="/services/hygiene-grooming">
                    · Hygiene & Grooming
                  </Link>
                </li>
                {/* <li className="">
                  <Link to="/services/medication-reminders">
                    · Medication Reminders
                  </Link>
                </li>
                <li className="">
                  <Link to="/services/treatment-therapy-reminder">
                    · Treatment & Therapy Reminder
                  </Link>
                </li>
                <li className="">
                  <Link to="/services/personal-support">
                    · Personal Support
                  </Link>
                </li> */}
                <li className="">
                  <a className="submenu-title" disabled>
                    Household Chores
                  </a>
                </li>
                {/* <li className="">
                  <Link to="/services/meal-prep">· Meal Prep</Link>
                </li>
                <li className="">
                  <Link to="/services/personal-laundry">
                    · Personal Laundry
                  </Link>
                </li> */}
                <li className="">
                  <Link to="/services/shopping-and-errands">
                    · Shopping and Errands
                  </Link>
                </li>
                <li className="">
                  <Link to="/services/housemaking">· Housemaking</Link>
                </li>
                <li className="">
                  <a className="submenu-title" disabled>
                    In-home support
                  </a>
                </li>
                <li className="">
                  <Link to="/services/in-home-family-support">
                    · In-home Family Support
                  </Link>
                </li>
                <li className="">
                  <Link to="/services/independent-living-skills">
                    · Independent/Semi-Independent Living Skills
                  </Link>
                </li>
                <li className="">
                  <Link to="/services/supported-living-services-for-adults">
                    · Supported Living Services for Adults
                  </Link>
                </li>
                <li className="">
                  <Link to="/services/individualized-home-support">
                    · Individualized home support
                  </Link>
                </li>
                <li className="">
                  <Link to="/services/respite-care">· Respite care</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link onClick={closeMenu} to="/contact">
                Contact us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
