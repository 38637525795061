import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as http from "../utils/http";
import { endpoints } from "../utils/const";
import Recaptcha from "react-recaptcha";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { servicesData } from "../utils/const";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router"; 
import { Modal, Button, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
         

const Contact = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => {
    if (location.state?.scrollToForm)
      document.getElementById("contact-form").scrollIntoView();
  }, [location.state?.scrollToForm]);
  const formik = useFormik({
    initialValues: {
      message: "",
      subject: "",
      name: "",
      email: "",
      service: "",
      recaptcha: "",
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .max(150, "Must be 150 characters or less")
        .required("Required"),
      name: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      subject: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      recaptcha: Yup.string().required(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
      http
        .post(endpoints.contact, null, values)
        .then((res) => res.data)
        .then((data) => {
          setSubmitting(false);
          console.log(data);
         // toast.success("Submitted successfully");
          setShow(true);
        })
        .catch((err) => {
          setSubmitting(false);
          toast.error("Error occured while submitting form");
        });
    },
  });
  return (
    <div>
      {formik.isSubmitting && (
        <div id="preloader-active">
          <div className="preloader d-flex align-items-center justify-content-center">
            <div className="preloader-inner position-relative">
              <div className="preloader-circle"></div>
              <div className="preloader-img pere-text">
                <img src={require("../utils/img/logo/loder.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="slider-area2">
        <div className="section-padding2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 text-center">
                  <h2>Contact Us</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            {/* <div className="col-12">
              <h2 className="contact-title">Find us on map</h2>
              <div
                style={{
                  background: "#fff",
                  width: "100%",
                  marginBottom: "20px",
                  overflow: "hidden",
                  borderRadius: "10px",
                  boxShadow: "0 0 10px rgba(221,221,221)",
                }}
              >
                <iframe
                  width="100%"
                  height="450"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(AONE%20Healthcare)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
            </div> */}
          </div>

          <div id="contact-form" className="row">
            <div className="col-12">
              <h2 className="contact-title">Get in Touch</h2>
            </div>
            <div className="col-lg-8">
              <form
                className="form-contact contact_form"
                action="contact_process.php"
                method="post"
                id="contactForm"
                noValidate="novalidate"
                onSubmit={formik.handleSubmit}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      {formik.touched.message && formik.errors.message ? (
                        <div className="text-form-error">
                          {formik.errors.message}
                        </div>
                      ) : null}
                      <textarea
                        className="form-control w-100"
                        name="message"
                        id="message"
                        cols={30}
                        rows={9}
                        placeholder=" Enter Message"
                        {...formik.getFieldProps("message")}
                      />
                      <span>Message</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      {formik.touched.name && formik.errors.name ? (
                        <div className="text-form-error">
                          {formik.errors.name}
                        </div>
                      ) : null}
                      <input
                        className="form-control valid"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Enter your name"
                        {...formik.getFieldProps("name")}
                      />
                      <span>Name</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-form-error">
                          {formik.errors.email}
                        </div>
                      ) : null}
                      <input
                        className="form-control valid"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Enter email"
                        {...formik.getFieldProps("email")}
                      />
                      <span>Email</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      {formik.touched.subject && formik.errors.subject ? (
                        <div className="text-form-error">
                          {formik.errors.subject}
                        </div>
                      ) : null}
                      <input
                        className="form-control"
                        name="subject"
                        id="subject"
                        type="text"
                        placeholder="Enter Subject"
                        {...formik.getFieldProps("subject")}
                      />
                      <span>Subject</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      {formik.touched.message && formik.errors.message ? (
                        <div className="text-form-error">
                          {formik.errors.service}
                        </div>
                      ) : null}
                      <select
                        className="form-control w-100"
                        name="service"
                        id="service"
                        {...formik.getFieldProps("service")}
                      >
                        <option value="" disabled selected>
                          Select Services
                        </option>
                        {servicesData &&
                          servicesData.map((serviceType) => {
                            return serviceType?.type ? (
                              <optgroup label={serviceType.type}>
                                {serviceType.services.map((service) => (
                                  <option id={service.id} value={service.value}>
                                    {service.title}
                                  </option>
                                ))}
                              </optgroup>
                            ) : (
                              serviceType.services.map((service) => (
                                <option id={service.id} value={service.value}>
                                  {service.title}
                                </option>
                              ))
                            );
                          })}
                      </select>
                      <span>Service</span>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  {formik.errors.recaptcha && formik.touched.recaptcha && (
                    <p>{formik.errors.recaptcha}</p>
                  )}
                  <Recaptcha
                    sitekey="6LePHMIZAAAAAJWQBZ6RnHQD0uCaONVktSNobOQX"
                    verifyCallback={(response) => {
                      formik.setFieldValue("recaptcha", response);
                    }}
                    onloadCallback={() => {}}
                  />
                </div>
                <div className="form-group mt-3">
                  <button
                    type="submit"
                    className="button button-contactForm boxed-btn"
                  >
                    Send
                  </button>
                </div>
                <Modal show={show} className="successModal" onHide={handleClose}>
                   <Modal.Header className="successModalHeader">
                        {/* <Modal.Title>Login Form</Modal.Title>  */}
                      </Modal.Header> 
                  <Modal.Body className="successModalBody">
                  Your data has been sent successfully
                  </Modal.Body>
                  <Modal.Footer className="successModalFooter">
                  <Link to="/" >
                    <Button className="successModalButton" variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Link>
                  </Modal.Footer>
                </Modal>
              </form>
            </div>
            <div className="col-lg-3 offset-lg-1 pt-5">
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-home" />
                </span>
                <div className="media-body">
                  <h3>5732 Pond Court </h3>
                  <p>Shoreview MN 55126</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-tablet" />
                </span>
                <div className="media-body">
                  <h3>6513781600</h3>
                  <h3>6129789262</h3>
                  <p>Mon to Fri 9am - 5 pm</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="fa fa-fax" />
                </span>
                <div className="media-body">
                  <h3>6122344284</h3>
                  <p>Fax Number</p>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon">
                  <i className="ti-email" />
                </span>
                <div className="media-body">
                  <h3>info@aonehomehealthcare.com</h3>
                  <p>Send us your query anytime!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
    </div>
  );
};

export default Contact;
