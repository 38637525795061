import React, { useEffect, useState } from "react";
import {
  useFormik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  Formik,
} from "formik";
import * as Yup from "yup";
import * as http from "../utils/http";
import { endpoints } from "../utils/const";
import { ToastContainer, toast } from "react-toastify";
import Recaptcha from "react-recaptcha";
import { Link } from "react-router-dom";               
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const Referral = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div>
      <div className="slider-area2">
        <div className="section-padding2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 text-center">
                  <h2>Refer Us</h2>
                  <p>
                    Contact us through friends that you have. Fill out the form
                    below to add multiple friends.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="contact-title">Referrer</h2>
            </div>
            <div className="col-12">
              <Formik
                initialValues={{
                  referrer_name: "",
                  referrer_email: "",
                  referrals: [
                    {
                      name: "",
                      phone: "",
                      email: "",
                    },
                  ],
                  recaptcha: "",
                }}
                validationSchema={Yup.object({
                  referrer_name: Yup.string()
                    .max(15, "Must be 15 characters or less")
                    .required("Required"),
                  referrer_email: Yup.string()
                    .email("Invalid email address")
                    .required("Required"),
                  recaptcha: Yup.string().required(),
                  referrals: Yup.array().of(
                    Yup.object({
                      name: Yup.string()
                        .max(15, "Must be 15 characters or less")
                        .required("Required"),
                      email: Yup.string()
                        .email("Invalid email address")
                        .required("Required"),
                      phone: Yup.number().required(
                        "A phone number is required"
                      ),
                    })
                  ),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  http
                    .post(endpoints.referral, null, values)
                    .then((res) => res.data)
                    .then((data) => {
                      setSubmitting(false);
                      console.log(data);
                      //toast.success("Submitted successfully");
                      setShow(true);
                    })
                    .catch((err) => {
                      setSubmitting(false);
                      toast.error("Error occured while submitting form");
                    });
                }}
                render={({ values, isSubmitting, setFieldValue }) => (
                  <Form className="form-contact contact_form">
                    {/* {isSubmitting && (
                      <div id="preloader-active">
                        <div className="preloader d-flex align-items-center justify-content-center">
                          <div className="preloader-inner position-relative">
                            <div className="preloader-circle"></div>
                            <div className="preloader-img pere-text">
                              <img
                                src={require("../utils/img/logo/loder.png")}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <ErrorMessage
                            className="text-form-error"
                            name="referrer_name"
                            component="p"
                          />
                          <Field
                            className="form-control valid"
                            name="referrer_name"
                            type="text"
                            placeholder="Enter Referrer Name"
                          />
                          <span>Name</span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <ErrorMessage
                            className="text-form-error"
                            name="referrer_email"
                            component="p"
                          />
                          <Field
                            className="form-control valid"
                            name="referrer_email"
                            type="email"
                            placeholder="Enter Referrer Email"
                          />
                          <span>Email</span>
                        </div>
                      </div>

                      <FieldArray
                        name="referrals"
                        render={(arrayHelpers) => (
                          <>
                            {values.referrals.map((referral, index) => (
                              <RefferalFormGroup
                                referral={referral}
                                remove={() => arrayHelpers.remove(index)}
                                index={index}
                                key={`ref-${index}`}
                              />
                            ))}
                            <div className="form-group mt-3">
                              <button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({
                                    name: "",
                                    email: "",
                                    phone: "",
                                  })
                                }
                                className="btn"
                                style={{width: 150+'px'}}
                              >
                                Add more +
                              </button>
                            </div>
                          </>
                        )}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <ErrorMessage
                        className="text-form-error"
                        name="recaptcha"
                        component="p"
                      />
                      <Recaptcha
                        sitekey="6LePHMIZAAAAAJWQBZ6RnHQD0uCaONVktSNobOQX"
                        verifyCallback={(response) =>
                          setFieldValue("recaptcha", response)
                        }
                      />
                    </div>
                    <div className="form-group mt-3">
                      <button
                        type="submit"
                        className="button button-contactForm boxed-btn"
                      >
                        Submit
                      </button>
                    </div>
                    <Modal show={show} className="successModal" onHide={handleClose}>
                      <Modal.Header className="successModalHeader">
                            {/* <Modal.Title>Login Form</Modal.Title>  */}
                      </Modal.Header> 
                      <Modal.Body className="successModalBody">
                        Your data has been sent successfully
                      </Modal.Body>
                      <Modal.Footer className="successModalFooter">
                      <Link to="/" >
                        <Button className="successModalButton" variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                      </Link>
                      </Modal.Footer>
                    </Modal>
                  </Form>
                )}
              ></Formik>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
    </div>
  );
};

const RefferalFormGroup = (props) => (
  <>
    <div className="col-12">
      <hr style={{ border: "1px solid grey", marginBottom: "10px" }} />
      <h2 className="contact-title">
        Refer-{props.index + 1}
        {props.index !== 0 && (
          <i
            className="ml-2 fas text-danger fa-trash bg-light p-2 rounded-circle"
            type="button"
            onClick={props.remove}
          ></i>
        )}
      </h2>
    </div>
    <div className="col-12 referral-single">
      <div className="form-group">
        <ErrorMessage
          className="text-form-error"
          name={`referrals[${props.index}].name`}
          component="p"
        />
        <Field
          className="form-control valid"
          name={`referrals[${props.index}].name`}
          type="text"
          placeholder="Enter name"
        />
        <span>Name</span>
      </div>
    </div>
    <div className="col-sm-6">
      <div className="form-group">
        <ErrorMessage
          className="text-form-error"
          name={`referrals[${props.index}].phone`}
          component="p"
        />
        <Field
          className="form-control valid"
          name={`referrals[${props.index}].phone`}
          type="text"
          placeholder="Enter phone number"
        />
        <span>Phone</span>
      </div>
    </div>
    <div className="col-sm-6">
      <div className="form-group">
        <ErrorMessage
          className="text-form-error"
          name={`referrals[${props.index}].email`}
          component="p"
        />
        <Field
          className="form-control valid"
          name={`referrals[${props.index}].email`}
          type="email"
          placeholder="Enter email"
        />
        <span>Email</span>
      </div>
    </div>
  </>
);

export default Referral;
