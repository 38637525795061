import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as http from "../utils/http";
import { endpoints } from "../utils/const";
import { ToastContainer, toast } from "react-toastify";
import Recaptcha from "react-recaptcha";
import "react-toastify/dist/ReactToastify.css";
import { toBase64 } from "../utils/utils";

const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const Careers = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      position: "",
      subject: "",
      message: "",
      recaptcha: "",
      resume: "",
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .max(150, "Must be 150 characters or less")
        .required("Required"),
      first_name: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      last_name: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      subject: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      position: Yup.string()
        .max(25, "Must be 25 characters or less")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      phone: Yup.string()
        .matches("[0-9 +]", "Looks Like Invalid Phone number")
        .min(9, "Looks Like Invalid phone number")
        .max(14, "Looks Like Invalid phone number")
        .required("A phone number is required"),
      resume: Yup.string()
        .required("Required")
        .test("SELECT_FILE", "Please Selct a File.", (v) => v !== "SELECT_FILE")
        .test(
          "INVALID_FILE_TYPE",
          "Invalid File Type.",
          (v) => v !== "INVALID_FILE_TYPE"
        )
        .test("INVALID_FILE", "Invalid File.", (v) => v !== "INVALID_FILE")
        .test(
          "FILE_SIZE",
          "File must not be larger than 2MB.",
          (v) => v !== "FILE_SIZE"
        ),
      recaptcha: Yup.string().required(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      http
        .post(endpoints.career, null, values)
        .then((res) => res.data)
        .then((data) => {
          setSubmitting(false);
          console.log(data);
          toast.success("Submitted successfully");
        })
        .catch((err) => {
          setSubmitting(false);
          console.log(err);
          toast.error("Error occured while submitting form");
        });
    },
  });

  const resumeChangeHandler = (e) => {
    const file = e.currentTarget.files[0];
    if (!file) {
      formik.setFieldValue("resume", "SELECT_FILE");
      e.currentTarget.value = "";
    } else if (file.size > 2 * 1024 * 1024) {
      formik.setFieldValue("resume", "FILE_SIZE");
      e.currentTarget.value = "";
    } else if (!SUPPORTED_FORMATS.includes(file.type)) {
      formik.setFieldValue("resume", "INVALID_FILE_TYPE");
      e.currentTarget.value = "";
    } else {
      toBase64(file)
        .then((data) => formik.setFieldValue("resume", data))
        .catch(() => {
          formik.setFieldValue("resume", "INVALID_FILE");
          e.currentTarget.value = "";
        });
    }
    formik.setFieldTouched("resume", true, false);
  };

  return (
    <div>
      {formik.isSubmitting && (
        <div id="preloader-active">
          <div className="preloader d-flex align-items-center justify-content-center">
            <div className="preloader-inner position-relative">
              <div className="preloader-circle"></div>
              <div className="preloader-img pere-text">
                <img src={require("../utils/img/logo/loder.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="slider-area2">
        <div className="section-padding2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 text-center">
                  <h2>Careers</h2>
                </div>
                <p className="text-center">
                  We can’t wait to welcome new members to our team! If
                  interested, please fill out the form below.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <h2 className="contact-title">Fill this Form</h2>
            <div>
              <form
                className="form-contact contact_form"
                id="contactForm"
                onSubmit={formik.handleSubmit}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      {formik.touched.first_name && formik.errors.first_name ? (
                        <div className="text-form-error">
                          {formik.errors.first_name}
                        </div>
                      ) : null}
                      <input
                        className="form-control valid"
                        name="first_name"
                        id="first_name"
                        type="text"
                        placeholder="Enter first name"
                        {...formik.getFieldProps("first_name")}
                      />
                      <span>First Name</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      {formik.touched.last_name && formik.errors.last_name ? (
                        <div className="text-form-error">
                          {formik.errors.last_name}
                        </div>
                      ) : null}
                      <input
                        className="form-control valid"
                        name="last_name"
                        id="last_name"
                        type="text"
                        placeholder="Enter last name"
                        {...formik.getFieldProps("last_name")}
                      />
                      <span>Last Name</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-form-error">
                          {formik.errors.email}
                        </div>
                      ) : null}
                      <input
                        className="form-control valid"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Enter email"
                        {...formik.getFieldProps("email")}
                      />
                      <span>Email</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="text-form-error">
                          {formik.errors.phone}
                        </div>
                      ) : null}
                      <input
                        className="form-control valid"
                        name="phone"
                        id="phone"
                        type="text"
                        placeholder="Enter phone number"
                        {...formik.getFieldProps("phone")}
                      />
                      <span>Phone</span>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group file-input-group">
                      {formik.touched.resume && formik.errors.resume ? (
                        <div className="text-form-error">
                          {formik.errors.resume}
                        </div>
                      ) : null}
                      <input
                        className="form-control valid file-input"
                        name="resume"
                        id="resume"
                        type="file"
                        placeholder="Upload your resume"
                        onChange={resumeChangeHandler}
                      />
                      <label htmlFor="resume">
                        Resume <i className="fas fa-info-circle"></i>
                        <div className="file-upload-info">
                          <p>· Maximum file size limit is 2MB. </p>
                          <p>· Supported formats are .pdf/.doc/.docx </p>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      {formik.touched.position && formik.errors.position ? (
                        <div className="text-form-error">
                          {formik.errors.position}
                        </div>
                      ) : null}
                      <input
                        className="form-control valid"
                        name="position"
                        id="position"
                        type="text"
                        placeholder="Position applying for"
                        {...formik.getFieldProps("position")}
                      />
                      <span>Position</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      {formik.touched.subject && formik.errors.subject ? (
                        <div className="text-form-error">
                          {formik.errors.subject}
                        </div>
                      ) : null}
                      <input
                        className="form-control"
                        name="subject"
                        id="subject"
                        type="text"
                        placeholder="Enter Subject"
                        {...formik.getFieldProps("subject")}
                      />
                      <span>Subject</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      {formik.touched.message && formik.errors.message ? (
                        <div className="text-form-error">
                          {formik.errors.message}
                        </div>
                      ) : null}
                      <textarea
                        className="form-control w-100"
                        name="message"
                        id="message"
                        cols={30}
                        rows={9}
                        placeholder="Enter your message here"
                        {...formik.getFieldProps("message")}
                      />
                      <span>Message</span>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  {formik.errors.recaptcha && formik.touched.recaptcha && (
                    <div className="text-form-error">
                      {formik.errors.recaptcha}
                    </div>
                  )}
                  <Recaptcha
                    sitekey="6LePHMIZAAAAAJWQBZ6RnHQD0uCaONVktSNobOQX"
                    verifyCallback={(response) =>
                      formik.setFieldValue("recaptcha", response)
                    }
                  />
                </div>
                <div className="form-group mt-3">
                  <button
                    type="submit"
                    className="button button-contactForm boxed-btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
    </div>
  );
};

export default Careers;
