import { servicesData } from "./const"

export const findServiceData = (id) => {
  let services = []
  servicesData.forEach(s => (services = services.concat(s.services)))
  return services.find(i => i.id === id)
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});