import React, { useEffect } from "react";

const About = () => {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <div className="slider-area2">
        <div className="section-padding2 d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="hero-cap hero-cap2 text-center">
                  <h2>About Us</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-area row">
        <div className="container">
          <div className="about-caption mt-50 mb-50">
            <div className="section-tittle section-tittle2 mb-35 row">
              <div className="col-lg-6 col-md-12">
                {/* <span>About Us</span> */}
                <p>
                  {" "}
                  At AOne Home Health Care we aim to provide all of the
                  resources that a client needs to improve their quality of
                  life. Our qualified caregivers are here to work with you to
                  develop and manage a personalized home health plan that’s most
                  convenient for you.
                </p>
                <p>
                  We are licensed by the Department of Human Services (DHS) to
                  provide Home and Community-Based Services(HCBS) to persons
                  with disabilities and older in the state of Minnesota, subject
                  to the standards of Minnesota Statutes and we are also
                  provider for PCA services .
                </p>
                <p>
                  As a PCA and 245D care agency, we had wide variety of services
                  to meet your home and personal support needs.
                </p>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="about-img">
                  {/* <div className="about-font-img d-none d-lg-block">
                    <img src={require("../utils/img/gallery/section_bg01.png")} alt="" />
                  </div> */}
                  <div className="about-back-img d-lg-block">
                    <img src={require("../utils/img/gallery/pic.jpg")} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
/*


*/
export default About;
