import Axios from "axios";
import { backendBaseUrl } from "./const";

const url = backendBaseUrl;

export function get(endpoint, params, config) {
  return Axios.get(getURL(endpoint, params), config);
}

export function post(endpoint, params, data, config) {
  return Axios.post(getURL(endpoint, params), data, config);
}

export function put(endpoint, params, data, config) {
  return Axios.put(getURL(endpoint, params), data, config);
}

export function patch(endpoint, params, data, config) {
  return Axios.patch(getURL(endpoint, params), data, config);
}

export function dlt(endpoint, data, config) {
  return Axios.delete(getURL(endpoint), data, config);
}

export function request(config) {
  return Axios.request(config);
}

export function getURL(endpoint, params = {}) {
  const url = backendBaseUrl;
  // console.log(url)
  // for (let key of params) {
  //   endpoint = endpoint.replace(new RegExp("\\{" + key + "\\}", "gm"), params[key])
  // }
  // console.log(url + endpoint)
  return url + endpoint;
}