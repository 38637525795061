import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div
        className="footer-area section-bg"
        data-background="assets/img/gallery/footer_bg.jpg"
      >
        <div className="container">
          <div className="footer-top footer-padding">
            <div className="row d-flex justify-content-between">
              <div className="col-lg-8">
                <div className="single-footer-caption mb-10 footer-logo-wrapper">
                  <img
                    className="footer-logo"
                    src={require("../utils/img/logo/logo-blue-removebg.png")}
                  />
                  <div className="footer-links single-footer-caption">
                    <p>Quick links</p>
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/careers">Careers</Link>
                      </li>
                      <li>
                        <Link to="/referral">Refer Us</Link>
                      </li>
                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 d-flex flex-column align-items-center align-self-center">
                <h4 className="text-white">Contact us here</h4>
                <div className="footer-number">
                  <p>
                    <i className="ti-tablet" /> 6513781600 , 6129789262
                  </p>
                  <p>
                    <i className="fa fa-fax" /> 6122344284
                  </p>
                  <p>
                    <i className="ti-email" /> info@aonehomehealthcare.com
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="single-footer-caption mb-10">
                  <div style={{ width: "100%" }}>
                    <iframe width="100%" height="200" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(AONE%20Healthcare)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-xl-9 col-lg-8">
                <div className="footer-copy-right">
                  <p>
                    {new Date().getFullYear()} AOne Home Healthcare Services LLC
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <div className="footer-social f-right">
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fas fa-globe"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
