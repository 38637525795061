export const backendBaseUrl = "https://aonehhc.herokuapp.com";

export const endpoints = {
  career: "/career",
  referral: "/referral",
  contact: "/contact",
};

export const servicesData = [
  {
    services: [
      {
        id: "pca-service",
        title: "PCA Service  /Community First Services and Supports (CFSS)",
        subtitle: "PCA Service  /Community First Services and Supports (CFSS)",
        image: require("../utils/img/gallery/pca-service.jpeg"),
        data: {
          p: [
            "As a provider for PCA / CFSS Services our Personal Care Assistance (PCA) is meant for  overall welfare of our clients.Our trained professionals staff deliver high-quality services.",
            "Our trained PCAs  assist and help in day to day activities like:",
          ],
          ul: [
            "Toileting",
            "Dressing",
            "Grooming",
            "Bathing",
            "Eating",
            "Transfers",
            "Positioning",
          ],
          summary: [
            "Personal Care Choice is a program that allows individuals/clients  to independently recruit, hire, and train the PCA of their choice and manage their care . AOne Home Health Care assist in handling the employment and management-related work  of their PCA.",
          ],
        },
      },
      {
        id: "245D-waivered-services",
        title: "245D Waivered Services",
        subtitle: "245D Waivered Services",
        image: require("../utils/img/gallery/245D-waivered-services.jpeg"),
        data: {
          p: [
            "AOne Home Health Care is is a licensed provider of 245D services, we are licensed by the Department of Human Services (DHS) to provide Home and Community-Based Services(HCBS)",
            "With our 245D waivered service, we can provide under mention quality services :",
          ],
          ul: [
            "<h3>Basic Support Services </h3>",
            "Adult Companion Services",
            "Night Supervision",
            "Homemaker Services",
            "Personal Support",
            "Individual Community Living Support (ICLS)",
            "Respite Care, In-home or Out-of-home",
            "<h3>In-Home Support Services</h3>",
            "Individualized Home Supports",
            "Independent Living Skills (ILS) Training",
          ],
        },
      },
    ],
  },
  // {
  //   services: [
  //     {
  //       id: "245D-waivered-services",
  //       title: "245D Waivered Services",
  //       subtitle: "245D Waivered Services",
  //       image: require("../utils/img/gallery/24-hr.jpg"),
  //       data: {
  //         p: [
  //           "Aone Home Health Care is is a licensed provider of 245D services, we are licensed by the Department of Human Services (DHS) to provide Home and Community-Based Services(HCBS)",
  //           "With our 245D waivered service, we can provide under mention quality services :"
  //           ],
  //         ul: [
  //           "<h3>Basic Support Services </h3>",
  //           "Adult Companion Services",
  //           "Night Supervision",
  //           "Homemaker Services",
  //           "Personal Support",
  //           "Individual Community Living Support (ICLS)",
  //           "Respite Care, In-home or Out-of-home",
  //           "<h3>In-Home Support Services</h3>",
  //           "Individualized Home Supports",
  //           "Independent Living Skills (ILS) Training",
  //         ]
  //       }
  //     }
  //   ]
  // },
  {
    type: "Basic Support Service",
    services: [
      {
        id: "24-hours-emergency-assistance",
        title: "24 Hours Emergency Assistance",
        subtitle: "Providing You With Round-the-clock Assistance",
        image: require("../utils/img/gallery/24-hr.jpg"),
        data: {
          p: [
            "The advances in home care over the last generation have made aging in place the most popular option among the senior population. However, seniors who choose to age in place need some level of care in their homes to ensure that they can remain at home safely.",
            "For some seniors, this could mean a few hours of assistance each day while for others, this may require full-time assistance and care. Every senior citizen has the right to remain living in their own home and for those that require full-time care, AOne Home Healthcare can help.",
            "With these services, we guarantee that a trained and certified caregiver will always be at the home of your loved one awake, alert, and ready to respond to anything. Our caregivers will cover everything from personal care and assistance with daily activities to meal preparation and light housekeeping, 24 hours a day.",
            "<h3>How do 24-Hour Home Care Services Work?</h3>",
            "When a senior needs 24 hour care services, we assign two caregivers who alternate 12 hour shifts to guarantee that there will be a caregiver awake in the home 24 hours a day. These services work much like our in-home care services in which we will meet with you and your loved ones at their home and create a personalized care plan based on the specific needs of the individual.",
            "<h3>Our 24 hour caregivers can provide the following:</h3>",
          ],
          ul: [
            "Help using the restroom during the day and in the middle of the night",
            "Help bathing and getting dressedDoes not receive 24-hour supervision",
            "Social companionship",
            "Light housekeeping including basic cleaning and laundry",
            "Meal preparation",
            "Immediate response for emergencies and the nearest relatives",
          ],
          summary: [
            "Most importantly, the 24-hour home care services from AOne Home Healthcare provide you and your loved ones with peace of mind knowing that there is always a skilled caregiver nearby to react to any issues or emergencies.",
          ],
        },
      },
      {
        id: "overnight-services",
        title: "Overnight Services",
        subtitle: "Night Supervision",
        image: require("../utils/img/gallery/overnight.jpg"),
        data: {
          p: [
            "When you have an elderly or disabled relative in your care, it seems like the 	worst always happens at night. Family caregivers are often dedicated individuals who put a lot of their time and effort into caring for their relatives, but no one can provide effective care 24 hours a day.",
            "Our overnight caregivers will arrive in the evening to help your loved ones with their bedtime routine and stay awake through the night to provide assistance when needed. You can choose to have our caregivers stay in the same room during the night or in another nearby area of the home.",
          ],
          ul: [
            "Assistance washing up and getting dressed before bed",
            "Help getting in bed at night and out of bed in the morning",
            "Immediate response in the middle of the night to help with restroom use and other scenarios",
          ],
        },
      },
      {
        id: "companionship",
        title: "Companionship",
        subtitle: "Let Us Be Your Partner To Wellness",
        image: require("../utils/img/gallery/companionship.jpg"),
        data: {
          p: [
            "Social interaction is vital to maintain physical and mental well-being. No wants to feel alone or isolated. Companion care can help to fill a social gap that often 	occurs as we grow older and lose our ability to drive or travel to visit friends and family. AOne Home Healthcare can provide a friendly, compassionate companion caregiver to help enrich your loved one's life by encouraging socialization and participating in joint activities such as:",
          ],
          ul: [
            "Conversation & Listening",
            "Playing Games",
            "Light Gardening",
            "Crafts",
            "Scrapbooking",
            "Attending Social Events",
            "Taking Walks & Exercising",
            "Enjoying Fresh Air Activities",
            "Running errands & Shopping",
            "Reading Assistance",
            "Correspondence Assistance",
          ],
        },
      },
      {
        id: "live-in-caregiver-services",
        title: "Live-In Caregiver Services",
        subtitle: "We Care For You",
        image: require("../utils/img/gallery/live-in-care.jpg"),
        data: {
          p: [
            "As we get older, we all need some extra help. But when it comes down to going to a nursing home or elderly care facility, or getting to stay in one’s home, your loved one probably already has a strong preference for staying where they’re most comfortable. Who can blame them? Staying in the place you call home, sleeping in your own bed, cooking in your own kitchen, all of these are understandably preferable to uprooting and living in an assisted living environment. While certain health restrictions or needs sometimes do require the services of a nursing home.",
            "AOne HealthCare offers a number of elderly care services, including live-in care giving options that could allow for your loved one to stay in their home while still getting the care and assistance they need.",
          ],
          ul: [],
        },
      },
    ],
  },
  {
    type: "Personal Support",
    services: [
      {
        id: "assistance-with-bathing-dressing",
        title: "Assistance with Bathing & Dressing",
        subtitle: "Assistance In Esentials",
        image: require("../utils/img/gallery/assistance.jpg"),
        data: {
          p: [
            "As we age, many personal tasks such as showering and dressing can become more difficult. Having proper care is very important to our elders physical and mental well-being. Often, helping our family members with these types of personal hygiene tasks can be uncomfortable. Our trained caregivers can assist your loved ones in performing these daily tasks while allowing them to maintain their sense of dignity.",
          ],
          ul: [],
        },
      },
      {
        id: "hygiene-grooming",
        title: "Serving And Grooming",
        subtitle: "Serving Your Basic Needs",
        image: require("../utils/img/gallery/hygine.jpg"),
        data: {
          p: [
            "Many personal tasks such as grooming can become more difficult. Having proper hygenic care is very important to our elders physical and mental well-being. Often, helping our family members with these types of personal hygeine tasks can be uncomfortable. Our trained caregivers can assist your loved ones in performing these daily tasks while allowing them to maintain their sense of dignity.",
          ],
          ul: [],
        },
      },
      // {
      //   id: "medication-reminders",
      //   title: "Medication Reminders",
      //   subtitle: "Keeping you as health as possible",
      //   image: "https://dta0yqvfnusiq.cloudfront.net/quali47282210/2018/08/Quality-Care-Service-Features3-5b7b1b34cb2b4.jpg",
      //   data: {
      //     p: [
      //       "If you’ve ever worried about a loved one not taking their medication on time, you’re not alone. We want our loved ones to be as healthy as possible, but recognize that this can be a challenge when medications are forgotten, or taken incorrectly. As our health needs change, the number of medications we need to take can often increase, and become more difficult to keep track of in the process.",
      //       "While Quality Care Companions cannot directly administer medications, we are more than happy to include medication reminders as a part of our home care service options.When a personal attendant is spending time with your loved one, they can help with a number of hygiene and health- related tasks, and can absolutely provide the medication reminder your loved one needs to stay on track.Learn more about this type of in-home care service, and contact our team today for unparalleled compassion and care.Quality Care Companions provides in-home senior care services throughout St.Paul and Minneapolis, and we’re based out of Eden Prairie."
      //     ],
      //     ul: [
      //       "Med Reminders: Verbal or visual reminders are given to help your loved one stay on track with their medication"
      //     ]
      //   }
      // },
      // {
      //   id: "treatment-therapy-reminder",
      //   title: "Treatment & Therapy Reminder",
      //   subtitle: "Exercise & Treatment Reminders",
      //   image: "https://dta0yqvfnusiq.cloudfront.net/quali47282210/2018/08/Quality-Care-Service-Features4-5b7b1b3171213.jpg",
      //   data: {
      //     p: [
      //       "It may seem strange to expect our elderly loved ones to be physically active, but this is one of the most important habits for everyone to practice, at every single age. Physical fitness is essential for improving bone and muscle strength, it releases endorphins, and can even help against degenerative disorders, such as Alzheimer’s disease and dementia. Of course, it’s most necessary to build these habits at an early age, but it’s never too late to build fitness into our day-to-day lives.",
      //       "With the compassionate care team from Quality Care Companions based here in Eden Prairie, your loved one can receive reminders for their various exercise and treatment regimens.Whether this means accompanying them on a walk, or reminding them to do their doctor- recommended stretches, our experienced staff will help them stay on track.See how this type of home care service works, and contact Quality Care Companions to get started.We cater to our neighbors throughout the Twin Cities."
      //     ],
      //     ul: [
      //       "Exercise Reminders: Verbal or visual reminders are given to help your loved one complete scheduled treatments and exercises."
      //     ]
      //   }
      // },
      // {
      //   id: "personal-support",
      //   title: "Personal Support",
      //   subtitle: "Committed to giving you peace of mind",
      //   image: "https://neemuhomehealthcare.com/wp-content/uploads/2020/08/thumb-1439004380.jpg",
      //   data: {
      //     p: ["Our Personal Support services in Neemu Home Healthcare Services are provided in the clients’ home or community to help them achieve their full potential, increase their independence, and integrate into the community based on their assessed needs. This service is important especially for those with mobility problems or experiencing a chronic illness that requires sufficient assistance. Our caregivers guarantee care and assistance that is readily available anytime their help is much needed.", "The covered services under personal support include supervision, support, or assistance with:"],
      //     ul: [
      //       "Achieve his/her full potential",
      //       "Increase his / her independence",
      //       "Meet community inclusion goals that are important to and important for the person and based on an assessed need"
      //     ]
      //   }
      // },
    ],
  },
  {
    type: "Household Chores",
    services: [
      // {
      //   id: "meal-prep",
      //   title: "Meal Prep",
      //   subtitle: "Making nutritious and delicious meals",
      //   data: {
      //     p: [
      //       "They may have once loved cooking in the kitchen, recreating favorites from their own childhood, but now your loved one might need some assistance in their later years. Or perhaps, they always relied on family members or a spouse for delicious meals, but that connection no longer exists. No matter what the case may be, there’s a good chance your loved one could benefit from the meal prep services from Quality Care Companions.",
      //       "Having nutritious(and delicious!) meals are necessary to living a healthy and balanced lifestyle, at any and all ages.With the elderly care services from Quality Care Companions, our compassionate team can provide snacks and meals that are both healthy and tasty, while also meeting any dietary restrictions that might be in place.Learn more about our meal prep service, and contact our elderly care team to get started! We serve residents throughout the Twin Cities, from our hometown of Eden Prairie."
      //     ],
      //     ul: [
      //       "We'll prepare meals to meet the needs of modified diets (diets that have been ordered by licensed health professionals)."
      //     ]
      //   }
      // },
      // {
      //   id: "personal-laundry",
      //   title: "Personal Laundry",
      //   subtitle: "Taking care of your laundry",
      //   data: {
      //     p: [
      //       "When we’re younger, laundry is a chore, and it’s not exactly the most fun activity, but it’s fairly manageable. As we age, our physical and mental capabilities change, and chores like laundry can become more of a challenge. When mobility is reduced or laundry facilities are inconvenient, our loved ones might need some extra help in their older age. Those with Alzheimer’s/dementia may also forget to take care of this routine task.",
      //       "With the senior home care options from Quality Care Companions, your loved one can stay in their home, enjoying their life, while still getting the care and assistance they need.Our laundry services will guarantee clean clothes that are regularly attended to, and always put away.We would be more than happy to help answer any questions you might have — contact our team today! We offer care services throughout St.Paul and Minneapolis from our hometown of Eden Prairie."
      //     ],
      //     ul: [
      //       "Clothes will be washed, dried, and folded to your loved one’s liking.",
      //       "Bedding and towels can also be taken care of when needed."
      //     ]
      //   }
      // },
      {
        id: "shopping-and-errands",
        title: "Shopping and Errands",
        subtitle: "Helping You Out",
        image: require("../utils/img/gallery/shooping.jpg"),
        data: {
          p: [
            "Grocery shopping for elderly adults can be difficult due to the following physical activities.",
          ],
          ul: [
            "Lifting heavy bags",
            "Bending and reaching for items",
            "Reading small labels",
            "Pushing shopping carts",
            "Walking and standing for a long period of time",
            "Driving a vehicle to and from the store",
          ],
          summary: [
            "Our caregivers are able to assist your loved ones by going grocery shopping to remove these physical strains on their body.",
          ],
        },
      },
      {
        id: "housemaking",
        title: "Housemaking",
        subtitle: "Keeping Your Home Safe And Comfortable",
        image: require("../utils/img/gallery/home-making.jpg"),
        data: {
          p: [
            "Sometimes our seniors and disabled family members need a little help keeping their house clean, organized and safe. Maintaining a clean house can prevent illnesses and reducing clutter can prevent safety hazards from developing. Our staff can help provide the following types of housekeeping services:",
          ],
          ul: [
            "Household Organization & Management",
            "Dusting",
            "Sweeping & Vacuuming",
            "Cleaning Kitchen Counters",
            "Washing dishes",
            "Bathroom Cleaning",
            "Laundry",
            "Ironing",
            "Linen Changes",
            "Watering plants",
            "Taking out trash",
          ],
        },
      },
    ],
  },
  {
    type: "In-Home Support",
    services: [
      {
        id: "in-home-family-support",
        title: "In-home Family Support",
        subtitle: "Personalized Services Tailored To Meet Your Needs",
        image: require("../utils/img/gallery/In-home.jpg"),
        data: {
          p: [
            "AOne Home Health Care Services offer in-home family support services to 	provide a person and his/her family, including extended family members, in the family’s home and/or in the community to enable the person to remain in or return to the home. This includes training of the person and family members to increase their capabilities to care for and maintain the person in the home. By this, we can help them regain their strength and independence so they can live their life fully.",
            "In Home Family support is intended for those who cannot live in his or her home without support.",
            "In - home family support includes:",
          ],
          ul: [
            "Bed - making and household chores",
            "Eating and the preparation of food",
            "Personal grooming and cleanliness",
            "Social and adaptive skills necessary to enable the person to reside in a non - institutional setting, including community participation",
            "Health, safety, and wellness",
            "Household management",
          ],
        },
      },
      {
        id: "independent-living-skills",
        title: "Independent/Semi-Independent Living Skills",
        subtitle: "Helping You Achieve Personal Goals",
        image: require("../utils/img/gallery/semi-independent.jpeg"),
        data: {
          p: [
            "Each of us deserves to live a life that is filled with opportunities to discover and achieve. At AOne Home Health Care Services, we understand that certain limitations such as age or disability may prevent us from achieving our greatest potential. This is why we work to provide adequate care and attention to your loved ones so that they are able to achieve their goals one by one.",
            "But with AOne Home Health Care Services, we understand that these hindrances cannot stop us forever. What they only need to do is to have adequate care and attention that will help them achieve their goals one by one.",
            "Our Independent Living Skills (ILS) services will help you or your loved one learn new skills that are essential for everyday living. For example, you can learn how to operate a washing machine and how to do the laundry. We have responsible and dependable professionals who will make sure that you or a loved one will stay safe and well-supervised throughout.",
            "Other skills we teach to you or your loved ones include:",
          ],
          ul: [
            "Learning basic household chores",
            "Learning how to better manage finances",
            "Knowing how to prepare meals",
            "Self-care",
            "Communication skills necessary for community interaction",
            "Community living and mobility",
            "And more",
            "<h3>In case of Semi-Independent Living Skills training we assist in following areas:</h3>",
            "Engage in activities that make it possible for an adult with developmental disabilities or related condition(s) to live in the community",
            "Exercise social, recreation, and transportation skills, including appropriate social behavior",
            "Learn and exercise the rights and responsibilities of community living",
            "Maintain personal appearance and hygiene",
            "Manage money, prepare meals, and shop",
            "Obtain and maintain a home",
            "Perform first aid and obtain assistance in an emergency",
            "Use the phone and other utilities.",
          ],
        },
      },
      {
        id: "supported-living-services-for-adults",
        title: "Supported Living Services For Adults",
        subtitle: "Professional Caregivers Providing Excellent Care",
        image: require("../utils/img/gallery/support-level.jpg"),
        data: {
          p: [
            "Aging in place has become the main choice for senior citizens as many more seniors are choosing to live in their homes than leave for care at a retirement home or assisted living facility. The higher number of seniors choosing to age in place means that there is a need for in-home care providers to help them live safely and comfortably.",
            "Seniors who choose to remain in their homes may need assistance with personal 	hygiene, transportation, and housekeeping to varying degrees. At AOne Home Health Care, we provide in-home care services for seniors aging in place.Our caregivers are ready to provide non-medical care and assistance to help your loved ones live comfortably and safely at home and provide full supported living life.",
          ],
          ul: [
            "Learning basic household chores",
            "Learning how to better manage finances",
            "Community living and mobility And more",
          ],
        },
      },
      {
        id: "individualized-home-support",
        title: "Individualized Home Support",
        subtitle: "Offering Assistance At Any Time",
        image: require("../utils/img/gallery/home-support.jpg"),
        data: {
          p: [
            "As we age, many personal tasks such as showering and grooming can become more difficult. Having proper hygienic care is very important to our elders physical and mental well-being. Often, helping our family members with these types of personal hygiene tasks can be uncomfortable. Our trained caregivers can assist your loved ones in performing these daily tasks while allowing them to maintain their sense of dignity. Some of the personal care services we can help with include:",
          ],
          ul: [
            "Bathing & Showering Assistance",
            "Incontinence Care",
            "Personal Grooming",
            "Oral Hygiene Care",
            "Dressing Assistance",
            "Transfer Assistance",
            "Meal Preparation & Planning",
            "Assistance with Pets",
            "Transportation to Appointments",
            "Banking and Bill Paying Assistance",
          ],
        },
      },
      {
        id: "respite-care",
        title: "Respite Care",
        subtitle: "Granting Family Caregivers The Time They Need To Relax",
        image: require("../utils/img/gallery/respite-care.jpg"),
        data: {
          p: [
            "Caregiver burnout becomes highly likely over time when a family member is the patient’s caregiver. A family caregiver, whether the patient’s spouse or adult child, definitely needs some time off to care for themselves. This need for time away is not an indication of lack of dedication to their loved one, instead it is a necessary act of self-renewal.",
            "AOne Home Health Care respite care caregivers provide family members with needed time off, whether it's for a few hours or for a weekend. We will be happy to care for your loved one during the time requested.",
          ],
          ul: [],
        },
      },
    ],
  },
];
