import React, { useState, useEffect } from "react"
import { servicesData } from "../../utils/const"
import { Link } from "react-router-dom"

const Services = () => {

  const [serviceId, setServiceId] = useState()
  const [currentService, setCurrentService] = useState()

  useEffect(() => {
    // setServiceId(0)
    // setCurrentService(servicesData[0])
  }, [])

  const changeService = (item, id) => {
    // setServiceId(id)
    // setCurrentService(item)
  }

  return (
    <div>
      <div className="department_area section-padding2">
        <div className="container">

          <div className="row">
            <div className="col-lg-12">
              <div className="section-tittle text-center mb-100">
                {/* <span>Our Services</span> */}
                <h2>Our Services</h2>
              </div>
            </div>
          </div>

          {servicesData && servicesData.map(service => 
            <div className="row">
              <div className="col-lg-12">
                <div className="text-primary mb-4">
                  <h3 className="font-weight-bold">{service.type}</h3>
                </div>
                <div className="row depart_ment_tab mb-30">
                  {/* <ul className="nav" id="myTab"> */}
                    {service.services && service.services.map((item, id) => (
                      <>
                        <div className="service col-sm-6 col-md-4">
                          <Link
                            to={{
                              pathname: `/services/${item.id}`,
                              state: { data: item }
                            }}
                            className={`${serviceId === id ? "active" : ""}`} onClick={() => changeService(item, id)}
                            style={{ background: `-webkit-linear-gradient(0deg, #24c0f100 0%, #4c9afe00 100%), url(${item.image}) no-repeat` }}
                          >
                            {/* <i className="flaticon-teeth"></i> */}
                            <h4>{item.title}</h4>
                          </Link>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          )}

          <div className="dept_main_info white-bg">
            {currentService && <div className="tab-content">
              <div className="tab-pane fade show active">
                <div className="row align-items-center no-gutters">
                    <div className="dept_thumb">
                      <img src={currentService.image} alt="" />
                    </div>
                    <div className="dept_info">
                      <h3>{currentService.title}</h3>
                      <hr style={{borderRadius:"3px", border:"2px solid white"}} />
                      <h3>{currentService.subtitle}</h3>
                      {currentService.data && (currentService.data.p).map(p => <p>{p}</p>)}
                      <ul className="list-group">
                      {currentService.data && (currentService.data.ul).map(li => <li style={{color:"white"}}>{li}</li>)}
                      </ul>
                    </div>
                </div>
              </div>
            </div>}
          </div>

        </div>
      </div>
    </div>
  )
}

export default Services