import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Services from "./pages/Services/Services";
import Contact from "./pages/Contact";
import Referral from "./pages/Refferal";
import Careers from "./pages/Carreers";
import Service from "./pages/Services/Service";
import About from "./pages/About";

const App = () => {
  return (
    <BrowserRouter onUpdate={() => {
      console.log("ROUTER ON UPDATE");
    }}>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/services/:serviceId" component={Service} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/careers" component={Careers} />
        <Route exact path="/referral" component={Referral} />
        <Route exact path="/about" component={About} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
