import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const [subject, setSubject] = useState();
  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    let x = 0;
    let s = [
      ["h", "e", "a", "l", "t", "h"],
      ["f", "i", "t", "n", "e", "s", "s"],
      ["l", "i", "f", "e"],
    ];
    setInterval(() => {
      setSubject(s[(++x + 1) % 3]);
    }, 2000);
  }, []);

  return (
    <div>
      {/* <div id="preloader-active">
                <div className="preloader d-flex align-items-center justify-content-center">
                    <div className="preloader-inner position-relative">
                        <div className="preloader-circle"></div>
                    </div>
                </div>
            </div> */}

      <main>
        <div className="slider-area position-relative">
          <div className="slider-active">
            <div className="single-slider slider-height d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-10 col-md-10 col-sm-12">
                    <div className="hero__caption">
                      {/* <span>Committed to success</span> */}
                      <h1 className="cd-headline">
                        We care about your
                        <div className="cd-words-wrapper">
                          {subject &&
                            subject.map((s, i) => <b key={"cd-" + i}>{s}</b>)}
                        </div>
                      </h1>
                      <p data-animation="fadeInLeft" data-delay="0.1s">
                        We consider it an honor to be entrusted with the daily
                        care and comfort of you or your loved one. We recognize
                        that each person has different needs and wants, so we
                        take a personalized approach to non-medical home care,
                        treating each senior with dignity and respect. To us,
                        you’re like family!
                      </p>
                      <Link
                        to="/contact"
                        className="btn hero-btn"
                        data-animation="fadeInLeft"
                        data-delay="0.5s"
                      >
                        Contact Us <i className="ti-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-area section-padding2">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10">
                <div className="about-caption mb-50">
                  <div className="section-tittle section-tittle2 mb-35">
                    <span>About Us</span>
                    <h2>Welcome To AOne Home Healthcare Services LLC</h2>
                  </div>
                  {/* <p>At Aone Home Health Care we provide high quality home care services to clients in the Twin Cities area. Since we have provided all the resources a clinet need in order to improve their quality of life. Our qualified caregivers are here to work with you to develop and manage a personalized home health plan that’s most convenient for you.</p> */}
                  <div className="about-btn1 mb-30">
                    <Link to="/about" className="btn about-btn">
                      Know More<i className="ti-arrow-right"></i>
                    </Link>
                  </div>
                  <div className="about-btn1 mb-30">
                    <Link to="/services" className="btn about-btn">
                      Services <i className="ti-arrow-right"></i>
                    </Link>
                  </div>
                  <div className="about-btn1 mb-30">
                    <Link to="/contact" className="btn about-btn">
                      Contact Us<i className="ti-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="about-img ">
                  {/* <div className="about-font-img d-none d-lg-block">
                                        <img src={require("../../utils/img/gallery/section_bg01.png")} alt="" />
                                    </div> */}
                  <div className="about-back-img ">
                    <img
                      src={require("../../utils/img/gallery/home-care.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
