import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { servicesData } from "../../utils/const";
import { findServiceData } from "../../utils/utils";

const Service = () => {
  const location = useLocation();
  const params = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    if (location.state && location.state.data) setData(location.state.data);
    else {
      let data = findServiceData(params.serviceId);
      data ? setData(data) : setData(null);
    }
  }, [location]);

  return data ? (
    <div className="department_area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-tittle mt-80 mb-80">
              <h3 className="text-primary">
                <Link to="/services">
                  <i className="fas fa-arrow-left" />
                  {"  Services "}
                </Link>
              </h3>
              <h2>{data.title}</h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center no-gutters dept_main_info">
          <div className="col-12">
            <div className="dept_thumb">
              <img src={data.image} alt />
            </div>
          </div>
          <div className="col-12">
            <div className="dept_info">
              <h3>{data.subtitle}</h3>
              <hr className="divider" />
              {data?.data?.p?.map((p) => <p dangerouslySetInnerHTML={{__html: p}}></p>)}
              <ul>
                {data?.data?.ul?.map((li) => (
                    <li>
                      {!li.startsWith('<h') && <i className="fas fa-chevron-circle-right mr-3"></i>}
                      {li.startsWith('<h') ? <p dangerouslySetInnerHTML={{__html: li}} /> : li}
                    </li>
                  ))}
              </ul>
              {data?.data?.summary?.map((p) => <p dangerouslySetInnerHTML={{__html: p}}></p>)}
              <Link
                className="dep-btn"
                to={{ pathname: "/contact", state: { service: data.title, scrollToForm: true } }}
              >
                Get Started
                <i className="ti-arrow-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div id="preloader-active">
      <div class="preloader d-flex align-items-center justify-content-center">
        <div class="preloader-inner position-relative">
          <div class="preloader-circle"></div>
          <div class="preloader-img pere-text">
            <img src={require("../../utils/img/logo/loder.png")} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
